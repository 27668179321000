<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import moment from 'moment'
import { postAction } from '@/command/netTool'
export default {
  data() {
    return {
      ...api.command.getState(),
      detail: {
        defaultNum: null,
        increasNum: null
      }
    }
  },
  watch: {},
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: `/market/farmLiveRoom/detail/${id}`
        })
        .then(result => {
          this.detail.functionConf = result.functionConf ? result.functionConf.split(',') : []
        })
  },
  methods: {
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '直播间标题',
            type: 'input',
            cols: 8,
            key: 'name',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '开始时间',
            type: 'datePicker',
            cols: 8,
            key: 'startTime',
            props: {
              showTime: true,
              format: 'YYYY-MM-DD HH:mm',
              valueFormat: 'YYYY-MM-DD HH:mm:ss'
            },
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '结束时间',
            type: 'datePicker',
            cols: 8,
            key: 'endTime',
            props: {
              showTime: true,
              format: 'YYYY-MM-DD HH:mm',
              valueFormat: 'YYYY-MM-DD HH:mm:ss'
            },
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          }
        ]
      }
    },
    getForm2() {
      return {
        type: 'cardForm',
        title:
          '直播间背景墙（直播间背景墙是每个直播间的默认背景。建议尺寸：600像素 * 1300像素，图片大小不得超过 3M。）',
        data: [
          {
            type: 'upload',
            maxLength: 1,
            cols: 24,
            key: 'bgImg',
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string'
              }
            ]
          }
        ]
      }
    },
    getForm3() {
      return {
        type: 'cardForm',
        title: '封面（图片建议大小为 800像素 * 450像素。图片大小不超过 300KB。）',
        data: [
          {
            type: 'upload',
            maxLength: 1,
            cols: 24,
            key: 'faceImg',
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string'
              }
            ]
          }
        ]
      }
    },
    getForm4() {
      return {
        title: '直播间功能',
        type: 'cardForm',
        gutter: 10,
        data: [
          {
            name: '功能',
            type: 'checkBoxButton',
            key: 'functionConf',
            cols: 12,
            typeData: [
              {
                name: '回放',
                value: '0'
              },
              {
                name: '评论',
                value: '1'
              },
              {
                name: '商品货架',
                value: '2'
              }
            ],
            rules: [
              {
                required: true,
                type: 'array'
              }
            ]
          }
        ]
      }
    },
    getForm5() {
      return {
        title: '作弊器',
        type: 'cardForm',
        gutter: 10,
        data: [
          {
            name: '直播开始后默认人数',
            type: 'inputNumber',
            key: 'defaultNum',
            cols: 12,
            rules: [
              {
                required: true,
                type: 'number'
              }
            ]
          },
          {
            name: '进入直播间每人增加数量',
            type: 'inputNumber',
            key: 'increasNum',
            cols: 12,
            rules: [
              {
                required: true,
                type: 'number'
              }
            ]
          }
        ]
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            console.log(data)

            if (moment(data.startTime) > moment(data.endTime)) {
              this.$message.warning('结束时间应在开始时间前！')
              return
            }

            api.command[data.id ? 'editPost' : 'create']
              .call(this, {
                url: `/market/farmLiveRoom/${data.id ? 'update' : 'add'}`,
                params: {
                  ...data,
                  functionConf: data.functionConf.toString()
                },
                isPost: false
              })
              .then(result => {
                this.$router.back()
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/marketingCenter/liveManage')
        }
      ]
      const right = [
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.delPostArr.call(this, {
              url: '/market/farmLiveRoom/deleteBatch',
              params: {
                idList: [this.detail.id]
              },
              isPost: false
            })
            setTimeout(() => {
              this.$router.push('/marketingCenter/liveManage')
            }, 500)
          }
        }
      ]
      return {
        left,
        right
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[this.getForm1(), this.getForm2(), this.getForm3(), this.getForm4(), this.getForm5()]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
